<template>
	<div class="flow-root">
		<ul class="-mb-8">
			<li v-for="(activityEvent, activityItemIdx) in activityStore.activityEvents.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? -1 : 1))" :key="activityEvent.id">
				<div class="relative pb-8">
					<span v-if="activityItemIdx !== activityStore.activityEvents.length - 1" aria-hidden="true" class="absolute top-5 left-6 -ml-px h-full w-0.5 bg-gray-400" />
					<div class="relative flex items-start space-x-3">
						<template v-if="activityEvent.event === 'interaction.created'">
							<div class="relative">
								<UserAvatar :first-name="activityEvent.causer.first_name ?? ''" :last-name="activityEvent.causer.last_name ?? ''"></UserAvatar>
								<span class="absolute -bottom-1 -right-1 bg-white rounded-tl h-5 -mt-1">
									<span class="flex items-center justify-center">
										<FontAwesomeIcon :icon="faCommentDots" class="p-px pt-0 h-4 w-4 text-primary-400" aria-hidden="true" />
									</span>
								</span>
							</div>
							<div class="min-w-0 flex-1">
								<div>
									<div class="text-sm">
										<span class="font-medium text-gray-900">{{ activityEvent.causer.first_name }} {{ activityEvent.causer.last_name }}</span>
										<span class="text-sm text-gray-900 mx-2">-</span>
										<span class="text-sm text-gray-500 hover:underline" :title="getLocalizedDate(activityEvent.caused_at).format('ddd, MMM D, YYYY HH:mm:ss ZZ (zzz)')">{{
											getRelativeFormatFrom(getLocalizedDate(activityEvent.caused_at))
										}}</span>
									</div>
									<p class="mt-0.5 text-sm text-gray-500">
										<span>{{ activityEvent.message }}</span>
									</p>
								</div>
								<div class="mt-2 text-sm text-gray-700">
									<!--suppress TypeScriptUnresolvedReference -->
									<div class="content ProseMirror" v-html="filterContent(activityEvent.subject.content)"></div>
								</div>
							</div>
						</template>
						<template v-if="activityEvent.event === 'form_request.activated' || activityEvent.event === 'form_request.in_progress' || activityEvent.event === 'form_request.submitted'">
							<div class="relative">
								<UserAvatar :first-name="activityEvent.causer.first_name ?? ''" :last-name="activityEvent.causer.last_name ?? ''"></UserAvatar>
								<span class="absolute -bottom-1 -right-1 bg-white rounded-tl h-5 -mt-1">
									<span class="flex items-center justify-center">
										<FontAwesomeIcon :icon="faClipboardListCheck" class="p-px pt-0 h-4 w-4 text-primary-400" aria-hidden="true" />
									</span>
								</span>
							</div>
							<div class="min-w-0 flex-1">
								<div>
									<div class="text-sm">
										<span class="font-medium text-gray-900">{{ activityEvent.causer.first_name }} {{ activityEvent.causer.last_name }}</span>
										<span class="text-sm text-gray-900 mx-2">-</span>
										<span class="text-sm text-gray-500 hover:underline" :title="getLocalizedDate(activityEvent.caused_at).format('ddd, MMM D, YYYY HH:mm:ss ZZ (zzz)')">{{
											getRelativeFormatFrom(getLocalizedDate(activityEvent.caused_at))
										}}</span>
									</div>
									<p class="mt-0.5 text-sm text-gray-500">
										<span>{{ activityEvent.message }}</span>
									</p>
								</div>
							</div>
						</template>
						<template v-if="activityEvent.event === 'activity.created' || activityEvent.event === 'activity.status.updated' || activityEvent.event === 'activity.initiator.complete' || activityEvent.event === 'activity.initiator.started'">
							<div class="relative">
								<UserAvatar :first-name="activityEvent.causer.first_name ?? ''" :last-name="activityEvent.causer.last_name ?? ''"></UserAvatar>
								<span class="absolute -bottom-1 -right-1 bg-white rounded-tl h-5 -mt-1">
									<span class="flex items-center justify-center">
										<FontAwesomeIcon v-if="activityEvent.event === 'activity.created'" :icon="faSparkles" class="p-px pt-0 h-4 w-4 text-primary-400" aria-hidden="true" />
										<FontAwesomeIcon v-else-if="activityEvent.event === 'activity.status.updated'" :icon="faPencil" class="p-px pt-0 h-4 w-4 text-primary-400" aria-hidden="true" />
										<FontAwesomeIcon v-else-if="activityEvent.event === 'activity.initiator.complete'" :icon="faClipboardListCheck" class="p-px pt-0 h-4 w-4 text-primary-400" aria-hidden="true" />
									</span>
								</span>
							</div>
							<div class="min-w-0 flex-1">
								<div>
									<div class="text-sm">
										<span class="font-medium text-gray-900">{{ activityEvent.causer.first_name }} {{ activityEvent.causer.last_name }}</span>
										<span class="text-sm text-gray-900 mx-2">-</span>
										<span class="text-sm text-gray-500 hover:underline" :title="getLocalizedDate(activityEvent.caused_at).format('ddd, MMM D, YYYY HH:mm:ss ZZ (zzz)')">{{
											getRelativeFormatFrom(getLocalizedDate(activityEvent.caused_at))
										}}</span>
									</div>
									<p class="mt-0.5"></p>
								</div>
								<div class="mt-0.5 text-sm text-gray-700">
									{{ activityEvent.message }}
								</div>
							</div>
						</template>
						<!--						<template v-else-if="activityEvent.event === 'assignment'">
                          <div>
                            <div class="relative px-1">
                              <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <FontAwesomeIcon :icon="faUserTag" class="ml-0.5 -mt-0.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                          <div class="min-w-0 flex-1 py-1.5">
                            <div class="text-sm text-gray-500">
                              <a :href="activityItem.person.href" class="font-medium text-gray-900">{{ activityItem.person.name }}</a>
                              {{ ' ' }}
                              assigned
                              {{ ' ' }}
                              <a :href="activityItem.assigned.href" class="font-medium text-gray-900">{{ activityItem.assigned.name }}</a>
                              {{ ' ' }}
                              <span class="whitespace-nowrap">{{ activityItem.date }}</span>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="activityEvent.event === 'tags'">
                          <div>
                            <div class="relative px-1">
                              <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <FontAwesomeIcon :icon="faTag" class="ml-px mt-0.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                          <div class="min-w-0 flex-1 py-0">
                            <div class="text-sm leading-8 text-gray-500">
                              <span class="mr-0.5">
                                <a :href="activityItem.person.href" class="font-medium text-gray-900">{{ activityItem.person.name }}</a>
                                {{ ' ' }}
                                added tags
                              </span>
                              {{ ' ' }}
                              <span class="mr-0.5">
                                <template v-for="tag in activityItem.tags" :key="tag.name">
                                  <a :href="tag.href" class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span class="absolute shrink-0 flex items-center justify-center">
                                      <span :class="[tag.color, 'h-1.5 w-1.5 rounded-full']" aria-hidden="true" />
                                    </span>
                                    <span class="ml-3.5 font-medium text-gray-900">{{ tag.name }}</span>
                                  </a>
                                  {{ ' ' }}
                                </template>
                              </span>
                              <span class="whitespace-nowrap">{{ activityItem.date }}</span>
                            </div>
                          </div>
                        </template>-->
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script lang="ts" setup>
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faClipboardListCheck, faCommentDots, faPencil, faSparkles } from '@fortawesome/pro-solid-svg-icons';
	import { useActivityStore } from '@modules/activities/store';
	import UserAvatar from '@modules/user/components/UserAvatar.vue';
	import { getLocalizedDate, getRelativeFormatFrom } from '@utils/useDate';
	import xss, { IFilterXSSOptions } from 'xss';
	import xssRules from '@/types/xss';

	const activityStore = useActivityStore();

	/**
	 * Filters and sanitizes the provided content string to prevent XSS (Cross-site Scripting) attacks.
	 *
	 * @param {string} content - The content string to be sanitized. Defaults to an empty string if null or undefined.
	 * @returns {string} The sanitized content string after applying XSS filtering rules.
	 */
	const filterContent = (content: string): string => {
		return xss(content ?? '', { xssRules, css: false } as IFilterXSSOptions);
	};
</script>
